@import "../../assets/styles/utils";

.index-banner{
  overflow: hidden;
  position: relative;
  @include res(width,100%);
  @include res(height,100vh);
  .swiper{
    height: 100%;
    .swiper-slide{
      position: relative;
      overflow: hidden;
      &.swiper-slide-active{
        b{
          transform: scale(1.05);
        }
        .banner-t{
          h1,h2{
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
      &.video{
        background-color: #000;
      }
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      b{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: transform 5s;
        transform-origin: center;
        transition-delay: 1s;
        &.pc-img{
          @include res(display,block,(sm:none));
        }
        &.mob-img{
          @include res(display,none,(sm:block));
        }
      }
      video{
        width: 120%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        z-index: 1;
        @include res(display,block,(md:none));
      }
      .banner-t{
        color: #fff;
        line-height: 1.4;
        position: absolute;
        left: 0;
        z-index: 3;
        text-transform: uppercase;
        width: 100%;
        box-sizing: border-box;
        @include res(bottom,1.6rem,(md:20%,xs:45%));
        h1{
          opacity: 0;
          transform: translateX(15%);
          transition: all .6s;
          transition-delay: 1s;
          color: $color-main;
          @include res(font-size,.58rem);
          &::before{
            content: '';
            display: block;
            background-color: #fff;
            @include res(width,.7rem);
            @include res(height,.04rem);
            @include res(margin-bottom, .2rem);
          }
        }
        h2{
          opacity: 0;
          transform: translateX(15%);
          transition: all .6s;
          transition-delay: 1.2s;
          @include res(font-size,.56rem,(xs:.4rem));
        }
      }
    }
    .swiper-contral{
      position: absolute;
      z-index: 3;
      @include res(bottom,1.8rem);
      @include res(right,2.6rem,(lg:2rem,md:1.6rem,sm:1rem,xs:.4rem));
      .swiper-mypagination{
        color: #fff;
        font-size: 14px;
        font-family: 'Microsoft YaHei';
        span{
          margin: 0 1px;
        }
      }
      .swiper-pagination{
        display: flex;
        position: static;
        @include res(margin-top,.15rem);
        .swiper-pagination-bullet{
          width: 5px;
          height: 5px;
          opacity: 1;
          background-color: #fff;
          position: relative;
          overflow: hidden;
          border-radius: 50px;
          &:not(:last-child){
            margin-right: 6px;
          }
          &::after{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: $color-main;
            position: absolute;
            top: 0;
            left: 0;
            transform-origin: left;
            transform: scaleX(0);
          }
          &.swiper-pagination-bullet-active{
            @include res(width,2.25rem);
            &::after{
              animation: progress 6s linear;
            }
          }
        }
      }
    }
  }
}

.section1{
  background-color: $color-bg;
  align-items: flex-end;
  justify-content: space-between;
  @include res(padding-top,1.3rem,(xs:1rem));
  @include res(padding-bottom,1.45rem,(xs:1rem));
  @include res(display,flex,(xs:block));
  .left-text{
    flex-shrink: 0;
    @include res(width,51.43%,(xs:100%));
    h2{
      line-height: 1;
      @include res(margin-bottom,.15rem);
      @include res(font-size,.25rem,(md:.3rem,sm:.4rem,xs:.3rem));
      @include res(letter-spacing,.01rem);
    }
    h1{
      line-height: 1;
      color: $color-main;
      @include res(font-size,.8rem,(md:.9rem,sm:1rem,xs:.8rem));
      @include res(margin-bottom,.35rem);
    }
    .desc{
      @include res(font-size,.17rem,(md:.28rem,sm:.34rem,xs:.3rem));
      @include res(margin-bottom,.3rem);
    }
    .link{
      @include res(text-align,right);
    }
  }
  .right-img{
    flex-shrink: 0;
    @include res(display,block,(xs:none));
    @include res(width,42.85%);
    img{
      display: block;
      height: auto;
      width: 100%;
    }
  }
}

.section2{
  position: relative;
  &::before{
    content: '';
    display: block;
    background-color: $color-bg;
    position: absolute;
    top: calc(0 - 1px);
    left: 0;
    z-index: -1;
    @include res(height,1rem);
    @include res(width,100%);
  }
  ul{
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    li{
      flex-shrink: 0;
      background-color: #fff;
      position: relative;
      transition: all .3s;
      box-shadow: 0rem .1rem .18rem rgba(0,0,0,.1);
      @include res(width,48%,(md:49%,xs:100%));
      @include res(margin-bottom,.3rem);
      &::before{
        content: '';
        display: block;
        width: 100%;
        background-color: $color-main;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: right;
        opacity: 0;
        // transform: scaleX(0);
        transition: opacity .3s;
        @include res(height,.03rem);
      }
      &:hover{
        transform: translateY(-.03rem);
        box-shadow: .05rem .15rem .2rem rgba(0,0,0,.1);
        &::before{
          transform-origin: left;
          // transform: scaleX(1);
          opacity: 1;
        }
      }
      a{
        align-items: center;
        justify-content: space-around;
        @include res(padding-top,.5rem);
        @include res(padding-bottom,.5rem);
        @include res(padding-left,.3rem,(md:0));
        @include res(padding-right,.3rem,(md:.5rem));
        @include res(display,flex);
        .item-img{
          flex-shrink: 0;
          @include res(width,1.9rem,(md:2.2rem,xs:30%));
          img{
            display: block;
            width: 100%;
            height: auto;
          }
        }
        .item-text{
          flex-shrink: 0;
          text-transform: uppercase;
          text-align: center;
          line-height: 1.6;
          @include res(font-size,.2rem,(md:.32rem,sm:.38rem,xs:.3rem));
          @include res(width,2.6rem,(md:4rem,sm:5rem,xs:60%));
        }
      }
    }
  }
}
.section2-other{
  position: relative;
  &::before{
    content: '';
    display: block;
    background-color: $color-bg;
    position: absolute;
    top: calc(0 - 1px);
    left: 0;
    z-index: -1;
    @include res(height,1rem);
    @include res(width,100%);
  }
  ul{
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    li{
      flex-shrink: 0;
      background-color: #fff;
      position: relative;
      transition: all .3s;
      box-shadow: 0rem .1rem .18rem rgba(0,0,0,.1);
      @include res(height,12vw,(md:16vw,sm:18vw,xs:35vw));
      @include res(width,48%,(md:49%,xs:100%));
      @include res(margin-bottom,.3rem);
      &::before{
        content: '';
        display: block;
        width: 100%;
        background-color: $color-main;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: right;
        opacity: 0;
        z-index: 1;
        // transform: scaleX(0);
        transition: opacity .3s;
        @include res(height,.03rem);
      }
      &:hover{
        transform: translateY(-.03rem);
        box-shadow: .05rem .15rem .2rem rgba(0,0,0,.1);
        &::before{
          transform-origin: left;
          // transform: scaleX(1);
          opacity: 1;
        }
      }
      .bg{
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
      a{
        align-items: center;
        justify-content: space-around;
        height: 100%;
        position: relative;
        z-index: 1;
        @include res(padding-top,.7rem);
        @include res(padding-bottom,.7rem);
        @include res(padding-left,40%,(xs:34%));
        @include res(padding-right,.3rem,(md:.5rem,xs:0));
        @include res(display,flex);
        .item-text{
          flex-shrink: 0;
          text-transform: uppercase;
          text-align: center;
          line-height: 1.6;
          @include res(font-size,.2rem,(md:.32rem,sm:.38rem,xs:.3rem));
          @include res(width,2.6rem,(md:4rem,sm:5rem,xs:60%));
        }
      }
    }
  }
}
// .section2-other{
//   position: relative;
//   &::before{
//     content: '';
//     display: block;
//     background-color: $color-bg;
//     position: absolute;
//     top: calc(0 - 1px);
//     left: 0;
//     z-index: -1;
//     @include res(height,1rem);
//     @include res(width,100%);
//   }
//   ul{
//     align-items: stretch;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     @include res(display,flex,(xs:block));
//     li{
//       flex-shrink: 0;
//       background-color: #fff;
//       position: relative;
//       transition: all .3s;
//       box-shadow: 0rem .1rem .18rem rgba(0,0,0,.1);
//       @include res(height,12vw,(md:16vw,sm:18vw,xs:35vw));
//       @include res(width,48%,(md:49%,xs:100%));
//       @include res(margin-bottom,.3rem);
//       &:hover{
//         transform: translateY(-.03rem);
//         box-shadow: .05rem .15rem .2rem rgba(0,0,0,.1);
//         .bg{
//           &::after{
//             background-color: rgba(0,0,0,.2);
//           }
//         }
//       }
//       .bg{
//         width: 100%;
//         height: 100%;
//         background-position: center;
//         background-size: cover;
//         position: absolute;
//         top: 0;
//         left: 0;
//         z-index: 0;
//         &::after{
//           content: '';
//           display: block;
//           width: 100%;
//           height: 100%;
//           background-color: rgba(0,0,0,.4);
//           position: absolute;
//           top: 0;
//           left: 0;
//           transition: all .3s;
//         }
//       }
//       a{
//         align-items: center;
//         justify-content: space-around;
//         height: 100%;
//         position: relative;
//         z-index: 1;
//         @include res(padding-top,.7rem);
//         @include res(padding-bottom,.7rem);
//         @include res(padding-left,.3rem,(md:0));
//         @include res(padding-right,.3rem,(md:.5rem));
//         @include res(display,flex);
//         .item-text{
//           flex-shrink: 0;
//           text-transform: uppercase;
//           text-align: center;
//           line-height: 1.6;
//           color: #fff;
//           @include res(font-size,.2rem,(md:.32rem,sm:.38rem,xs:.3rem));
//           @include res(width,2.6rem,(md:4rem,sm:5rem,xs:60%));
//         }
//       }
//     }
//   }
// }

.section3{
  @include res(margin-top,1rem);
  .sec3-title{
    text-align: center;
    color: #010101;
    text-transform: uppercase;
    @include res(margin-bottom,.6rem,(sm:.4rem));
    @include res(font-size,.4rem,(md:.5rem,sm:.6rem,xs:.5rem));
  }
  .news-list{
    flex-wrap: nowrap;
    @include res(justify-content,center);
    li{
      @include res(margin-bottom, 0,(xs:.4rem));
      &:not(:last-child){
        @include res(margin-right, .7rem,(md:.2rem,xs:0));
      }
    }
  }
  .sec3-link{
    text-align: center;
    @include res(margin-top,.65rem);
  }
}