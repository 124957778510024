.index-banner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
}

.index-banner .swiper {
  height: 100%;
}

.index-banner .swiper .swiper-slide {
  position: relative;
  overflow: hidden;
}

.index-banner .swiper .swiper-slide.swiper-slide-active b {
  transform: scale(1.05);
}

.index-banner .swiper .swiper-slide.swiper-slide-active .banner-t h1, .index-banner .swiper .swiper-slide.swiper-slide-active .banner-t h2 {
  opacity: 1;
  transform: translateX(0);
}

.index-banner .swiper .swiper-slide.video {
  background-color: #000;
}

.index-banner .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.index-banner .swiper .swiper-slide b {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 5s;
  transform-origin: center;
  transition-delay: 1s;
}

.index-banner .swiper .swiper-slide b.pc-img {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide b.pc-img {
    display: none;
  }
}

.index-banner .swiper .swiper-slide b.mob-img {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide b.mob-img {
    display: block;
  }
}

.index-banner .swiper .swiper-slide video {
  width: 120%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  display: block;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide video {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .banner-t {
  color: #fff;
  line-height: 1.4;
  position: absolute;
  left: 0;
  z-index: 3;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  bottom: 1.6rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .banner-t {
    bottom: 20%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .banner-t {
    bottom: 45%;
  }
}

.index-banner .swiper .swiper-slide .banner-t h1 {
  opacity: 0;
  transform: translateX(15%);
  transition: all .6s;
  transition-delay: 1s;
  color: #00a0e8;
  font-size: 0.58rem;
}

.index-banner .swiper .swiper-slide .banner-t h1::before {
  content: '';
  display: block;
  background-color: #fff;
  width: 0.7rem;
  height: 0.04rem;
  margin-bottom: 0.2rem;
}

.index-banner .swiper .swiper-slide .banner-t h2 {
  opacity: 0;
  transform: translateX(15%);
  transition: all .6s;
  transition-delay: 1.2s;
  font-size: 0.56rem;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .banner-t h2 {
    font-size: 0.4rem;
  }
}

.index-banner .swiper .swiper-contral {
  position: absolute;
  z-index: 3;
  bottom: 1.8rem;
  right: 2.6rem;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-contral {
    right: 2rem;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-contral {
    right: 1.6rem;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-contral {
    right: 1rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-contral {
    right: 0.4rem;
  }
}

.index-banner .swiper .swiper-contral .swiper-mypagination {
  color: #fff;
  font-size: 14px;
  font-family: 'Microsoft YaHei';
}

.index-banner .swiper .swiper-contral .swiper-mypagination span {
  margin: 0 1px;
}

.index-banner .swiper .swiper-contral .swiper-pagination {
  display: flex;
  position: static;
  margin-top: 0.15rem;
}

.index-banner .swiper .swiper-contral .swiper-pagination .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  opacity: 1;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
}

.index-banner .swiper .swiper-contral .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
  margin-right: 6px;
}

.index-banner .swiper .swiper-contral .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #00a0e8;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left;
  transform: scaleX(0);
}

.index-banner .swiper .swiper-contral .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 2.25rem;
}

.index-banner .swiper .swiper-contral .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  animation: progress 6s linear;
}

.section1 {
  background-color: #f4f4f4;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 1.3rem;
  padding-bottom: 1.45rem;
  display: flex;
}

@media (max-width: 767px) {
  .section1 {
    padding-top: 1rem;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .section1 {
    display: block;
  }
}

.section1 .left-text {
  flex-shrink: 0;
  width: 51.43%;
}

@media (max-width: 767px) {
  .section1 .left-text {
    width: 100%;
  }
}

.section1 .left-text h2 {
  line-height: 1;
  margin-bottom: 0.15rem;
  font-size: 0.25rem;
  letter-spacing: 0.01rem;
}

@media (max-width: 1024px) {
  .section1 .left-text h2 {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section1 .left-text h2 {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section1 .left-text h2 {
    font-size: 0.3rem;
  }
}

.section1 .left-text h1 {
  line-height: 1;
  color: #00a0e8;
  font-size: 0.8rem;
  margin-bottom: 0.35rem;
}

@media (max-width: 1024px) {
  .section1 .left-text h1 {
    font-size: 0.9rem;
  }
}

@media (max-width: 991px) {
  .section1 .left-text h1 {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .section1 .left-text h1 {
    font-size: 0.8rem;
  }
}

.section1 .left-text .desc {
  font-size: 0.17rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 1024px) {
  .section1 .left-text .desc {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .section1 .left-text .desc {
    font-size: 0.34rem;
  }
}

@media (max-width: 767px) {
  .section1 .left-text .desc {
    font-size: 0.3rem;
  }
}

.section1 .left-text .link {
  text-align: right;
}

.section1 .right-img {
  flex-shrink: 0;
  display: block;
  width: 42.85%;
}

@media (max-width: 767px) {
  .section1 .right-img {
    display: none;
  }
}

.section1 .right-img img {
  display: block;
  height: auto;
  width: 100%;
}

.section2 {
  position: relative;
}

.section2::before {
  content: '';
  display: block;
  background-color: #f4f4f4;
  position: absolute;
  top: calc(0 - 1px);
  left: 0;
  z-index: -1;
  height: 1rem;
  width: 100%;
}

.section2 ul {
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section2 ul {
    display: block;
  }
}

.section2 ul li {
  flex-shrink: 0;
  background-color: #fff;
  position: relative;
  transition: all .3s;
  box-shadow: 0rem 0.1rem 0.18rem rgba(0, 0, 0, 0.1);
  width: 48%;
  margin-bottom: 0.3rem;
}

@media (max-width: 1024px) {
  .section2 ul li {
    width: 49%;
  }
}

@media (max-width: 767px) {
  .section2 ul li {
    width: 100%;
  }
}

.section2 ul li::before {
  content: '';
  display: block;
  width: 100%;
  background-color: #00a0e8;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: right;
  opacity: 0;
  transition: opacity .3s;
  height: 0.03rem;
}

.section2 ul li:hover {
  transform: translateY(-0.03rem);
  box-shadow: 0.05rem 0.15rem 0.2rem rgba(0, 0, 0, 0.1);
}

.section2 ul li:hover::before {
  transform-origin: left;
  opacity: 1;
}

.section2 ul li a {
  align-items: center;
  justify-content: space-around;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  display: flex;
}

@media (max-width: 1024px) {
  .section2 ul li a {
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .section2 ul li a {
    padding-right: 0.5rem;
  }
}

.section2 ul li a .item-img {
  flex-shrink: 0;
  width: 1.9rem;
}

@media (max-width: 1024px) {
  .section2 ul li a .item-img {
    width: 2.2rem;
  }
}

@media (max-width: 767px) {
  .section2 ul li a .item-img {
    width: 30%;
  }
}

.section2 ul li a .item-img img {
  display: block;
  width: 100%;
  height: auto;
}

.section2 ul li a .item-text {
  flex-shrink: 0;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.6;
  font-size: 0.2rem;
  width: 2.6rem;
}

@media (max-width: 1024px) {
  .section2 ul li a .item-text {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .section2 ul li a .item-text {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section2 ul li a .item-text {
    font-size: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section2 ul li a .item-text {
    width: 4rem;
  }
}

@media (max-width: 991px) {
  .section2 ul li a .item-text {
    width: 5rem;
  }
}

@media (max-width: 767px) {
  .section2 ul li a .item-text {
    width: 60%;
  }
}

.section2-other {
  position: relative;
}

.section2-other::before {
  content: '';
  display: block;
  background-color: #f4f4f4;
  position: absolute;
  top: calc(0 - 1px);
  left: 0;
  z-index: -1;
  height: 1rem;
  width: 100%;
}

.section2-other ul {
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section2-other ul {
    display: block;
  }
}

.section2-other ul li {
  flex-shrink: 0;
  background-color: #fff;
  position: relative;
  transition: all .3s;
  box-shadow: 0rem 0.1rem 0.18rem rgba(0, 0, 0, 0.1);
  height: 12vw;
  width: 48%;
  margin-bottom: 0.3rem;
}

@media (max-width: 1024px) {
  .section2-other ul li {
    height: 16vw;
  }
}

@media (max-width: 991px) {
  .section2-other ul li {
    height: 18vw;
  }
}

@media (max-width: 767px) {
  .section2-other ul li {
    height: 35vw;
  }
}

@media (max-width: 1024px) {
  .section2-other ul li {
    width: 49%;
  }
}

@media (max-width: 767px) {
  .section2-other ul li {
    width: 100%;
  }
}

.section2-other ul li::before {
  content: '';
  display: block;
  width: 100%;
  background-color: #00a0e8;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: right;
  opacity: 0;
  z-index: 1;
  transition: opacity .3s;
  height: 0.03rem;
}

.section2-other ul li:hover {
  transform: translateY(-0.03rem);
  box-shadow: 0.05rem 0.15rem 0.2rem rgba(0, 0, 0, 0.1);
}

.section2-other ul li:hover::before {
  transform-origin: left;
  opacity: 1;
}

.section2-other ul li .bg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.section2-other ul li a {
  align-items: center;
  justify-content: space-around;
  height: 100%;
  position: relative;
  z-index: 1;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 40%;
  padding-right: 0.3rem;
  display: flex;
}

@media (max-width: 767px) {
  .section2-other ul li a {
    padding-left: 34%;
  }
}

@media (max-width: 1024px) {
  .section2-other ul li a {
    padding-right: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section2-other ul li a {
    padding-right: 0;
  }
}

.section2-other ul li a .item-text {
  flex-shrink: 0;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.6;
  font-size: 0.2rem;
  width: 2.6rem;
}

@media (max-width: 1024px) {
  .section2-other ul li a .item-text {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .section2-other ul li a .item-text {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .section2-other ul li a .item-text {
    font-size: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section2-other ul li a .item-text {
    width: 4rem;
  }
}

@media (max-width: 991px) {
  .section2-other ul li a .item-text {
    width: 5rem;
  }
}

@media (max-width: 767px) {
  .section2-other ul li a .item-text {
    width: 60%;
  }
}

.section3 {
  margin-top: 1rem;
}

.section3 .sec3-title {
  text-align: center;
  color: #010101;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
  font-size: 0.4rem;
}

@media (max-width: 991px) {
  .section3 .sec3-title {
    margin-bottom: 0.4rem;
  }
}

@media (max-width: 1024px) {
  .section3 .sec3-title {
    font-size: 0.5rem;
  }
}

@media (max-width: 991px) {
  .section3 .sec3-title {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-title {
    font-size: 0.5rem;
  }
}

.section3 .news-list {
  flex-wrap: nowrap;
  justify-content: center;
}

.section3 .news-list li {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section3 .news-list li {
    margin-bottom: 0.4rem;
  }
}

.section3 .news-list li:not(:last-child) {
  margin-right: 0.7rem;
}

@media (max-width: 1024px) {
  .section3 .news-list li:not(:last-child) {
    margin-right: 0.2rem;
  }
}

@media (max-width: 767px) {
  .section3 .news-list li:not(:last-child) {
    margin-right: 0;
  }
}

.section3 .sec3-link {
  text-align: center;
  margin-top: 0.65rem;
}
